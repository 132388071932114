import { Component } from '@angular/core';
import { single } from '../../fake-data/data';

@Component({
  selector: 'app-lm-subscription-reporting',
  templateUrl: './lm-subscription-reporting.component.html',
  styleUrls: ['./lm-subscription-reporting.component.scss']
})
export class LmSubscriptionReportingComponent {

  single = [];
  view: [number, number] = [900, 400];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: any = 'right';

  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor() {
    Object.assign(this, { single });
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}