import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { LmTransferModule } from './lm-transfers/lm-transfers.module';
import { LmLoginModule } from './lm-login/lm-login.module';
import { LmResetpasswordModule } from './lm-resetpassword/lm-resetpassword.module';
import { LmCreateUserModule } from "./lm-create-user/lm-create-user.module";
import { LmBanProfessionalModule } from './lm-ban-professional/lm-ban-professional.module';
import { LmUnbanProfessionalModule } from './lm-unban-professional/lm-unban-professional.module';
import { LmTransferPaybyModule } from './lm-transfer-payby/lm-transfer-payby.module';
import { LmTransferSepaModule } from './lm-transfer-sepa/lm-transfer-sepa.module';
import { ModalPaybyComponent } from './modals/modal-payby/modal-payby.component';
import { ModalSepaComponent } from './modals/modal-sepa/modal-sepa.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { LmProfessionalsModule } from './lm-professionals/lm-professionals.module';
import { LmJourneyDetailsModule } from './journey-details/lm-journey-details.module';
import { ModalCancelSepaComponent } from './modals/modal-cancel-sepa/modal-cancel-sepa.component';
import { LmClientsModule } from './lm-clients/lm-clients.module';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ProfessionalModalComponent } from './lm-transfers/sections/professional-dialog/professional-dialog';
import { ImagesDialogComponent } from './lm-transfers/sections/images-dialog/images-dialog.component';
import { ChatsDialogComponent } from './lm-transfers/sections/chats-dialog/chats-dialog.component';
import { LmFiltersOperationsComponent } from './lm-transfers/sections/lm-filters-operations/lm-filters-operations.component';
import { OperationsComponent } from './lm-transfers/operations/operations.component';
import { LmFiltersRequestsComponent } from './lm-transfers/sections/lm-filters-requests/lm-filters-requests.component';
import { RequestsComponent } from './lm-transfers/requests/requests.component';
import { LmFiltersBillingComponent } from './lm-transfers/sections/lm-filters-billing/lm-filters-billing.component';
import { BillingComponent } from './lm-transfers/billing/billing.component';
import { LmFiltersPaycometComponent } from './lm-transfers/sections/lm-filters-paycomet/lm-filters-paycomet.component';
import { PaycometOperationsComponent } from './lm-transfers/paycomet/paycomet-operations/paycomet-operations.component';
import { LmFiltersComponent } from './lm-transfers/sections/lm-filters/lm-filters.component';
import { LmTransfersComponent } from './lm-transfers/component/lm-transfers.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MessageDialogModule } from './modals/message-dialog/message-dialog.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SubscriptionModalComponent } from './modals/subscription-dialog/subscription-dialog.component';
import { LmSubscriptionReportingModule } from './lm-subscription-reporting/lm-subscription-reporting.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		LmLoginModule,
		LmTransferModule,
		LmResetpasswordModule,
		LmCreateUserModule,
		LmBanProfessionalModule,
		LmUnbanProfessionalModule,
		LmTransferPaybyModule,
		LmTransferSepaModule,
		LmProfessionalsModule,
		LmClientsModule,
		LmJourneyDetailsModule,
		MatDialogModule,
		MatIconModule,
		MatTableModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatToolbarModule,
		MatDialogModule,
		MatInputModule,
		MatDatepickerModule,
		MatIconModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		MatPaginatorModule,
		MatCheckboxModule,
		MatRadioModule,
		MatMenuModule,
		MatSelectModule,
		MatOptionModule,
		MatCardModule,
		MatExpansionModule,
		NgMultiSelectDropDownModule,
		MatTooltipModule,
		SharedModule,
		MatAutocompleteModule,
		MessageDialogModule
	],
	exports: [
		LmLoginModule,
		LmTransferModule,
		LmResetpasswordModule,
		LmCreateUserModule,
		LmBanProfessionalModule,
		LmUnbanProfessionalModule,
		LmTransferPaybyModule,
		LmTransferSepaModule,
		LmProfessionalsModule,
		LmClientsModule,
		LmJourneyDetailsModule,
		LmSubscriptionReportingModule,
		SharedModule
	],
	declarations: [
		LmTransfersComponent,
		LmFiltersComponent,
		PaycometOperationsComponent,
		LmFiltersPaycometComponent,
		BillingComponent,
		LmFiltersBillingComponent,
		RequestsComponent,
		LmFiltersRequestsComponent,
		OperationsComponent,
		LmFiltersOperationsComponent,
		ChatsDialogComponent,
		ImagesDialogComponent,
		ProfessionalModalComponent,
		ModalPaybyComponent, ModalSepaComponent, ConfirmDialogComponent, ModalCancelSepaComponent, SubscriptionModalComponent],
	providers: [
		{
			provide: MatDialogRef,
			useValue: {}
		},
		MatDatepickerModule
	]
})

export class LmPagesModule { }
