import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SubscriptionService } from "../../lm-professionals/services/subscription.service";
import { ISubscriptionChange } from "../../lm-professionals/models/lm-professional.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProfessionalService } from "../../lm-professionals/services/professional.service";


@Component({
    selector: 'app-subscription-modal',
    templateUrl: './subscription-dialog.component.html',
    styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionModalComponent implements OnInit {

    @Output() submitClicked = new EventEmitter<any>();

    loading: boolean;
    subscriptions: ISubscriptionChange[] = [];
    subscriptionForm: FormGroup;
    currentSubscription: ISubscriptionChange;
    type: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _subscriptionService: SubscriptionService,
        private _professionalService: ProfessionalService,
        private fb: FormBuilder) {
        this.subscriptionForm = this.fb.group({
            professionalId: [null, Validators.required],
            subscriptionCode: [null, Validators.required],
            subscriptionStatusCode: [null, Validators.required],
            comment: [null, [Validators.required, Validators.minLength(15), Validators.maxLength(500)]],
            subscriptionCodeTarget: [null]
        });
        this.currentSubscription = this.data['value']['subscription'];
    }

    ngOnInit(): void {
        const value = this.data['value'];
        this.type = value['type'];
        if (this.type === 'EDIT') {
            this.retrieveProfessionalSubscriptionAllowed();
        } else {
            this.retrieveSubscriptionAllowed();
        }
        this.subscriptionForm.controls.professionalId.setValue(Number(value['professionalId']));
        this.subscriptionForm.controls.subscriptionStatusCode.setValue('PENDING_PAYMENT');
    }

    /**
     * Retrieve Professional Subscription Allowed
     */
    retrieveProfessionalSubscriptionAllowed(): void {
        this._subscriptionService.retrieveProfessionalSubcriptionAllowed(this.data['value']['subscriptionCode'])
            .subscribe({
                next: res => {
                    this.buildSubscriptions(res);
                },
                error: () => {
                    this.subscriptions = [];
                }
            });
    }

    retrieveSubscriptionAllowed(): void {
        this._subscriptionService.retrieveSubscriptions()
            .subscribe({
                next: res => {
                    this.subscriptions = res.data;
                    this.subscriptions.sort((a, b) => a.id - b.id);
                },
                error: () => {
                    this.subscriptions = [];
                }
            });
    }

    /**
     * Build subscription for seleted
     * @param res 
     */
    buildSubscriptions(res: any): void {
        const subscriptionsWithFalseCurrent = res.data.map((subscription: ISubscriptionChange) => ({
            ...subscription,
            current: false
        }));

        this.subscriptions = [
            ...subscriptionsWithFalseCurrent,
            {
                id: this.currentSubscription.id,
                code: this.currentSubscription.code,
                current: true,
                subscriptionDescriptions: this.currentSubscription.subscriptionDescriptions
            }
        ].sort((a, b) => a.id - b.id);
        this.subscriptionForm.controls.subscriptionCode.setValue(this.currentSubscription.code);
    }

    /**
     * Close dialog
     */
    dismiss() {
        this.submitClicked.emit('close');
    }

    /**
     * Select the subscription
     * @param itemSelected 
     */
    changeSubscription(itemSelected: ISubscriptionChange): void {
        const selectedSubscription = this.subscriptions.find(item => item.id === itemSelected.id);
        if (selectedSubscription) {
            if (selectedSubscription.current === false) {
                this.subscriptionForm.patchValue({
                    subscriptionCodeTarget: selectedSubscription.code
                });
            } else {
                this.subscriptionForm.patchValue({
                    subscriptionCodeTarget: null
                });
            }
        }
    }

    /**
     * Submit changes
     */
    submit(): void {
        this.loading = true;
        if (this.type === 'EDIT') {
            this.subscriptionForm.controls.subscriptionCode.setValue(this.currentSubscription.code);
        }
        if (this.subscriptionForm.valid) {
            this._professionalService.changeProfessionalSubscription(this.data['value']['professionalId'], this.subscriptionForm.value).subscribe({
                next: (res) => {
                    if (res.code === '200') {
                        this.loading = false;
                        this.submitClicked.emit('submit');
                    }
                },
                error: () => {
                    this.loading = true;
                }
            });
        }
    }
}