import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LmSubscriptionReportingComponent } from './component/lm-subscription-reporting/lm-subscription-reporting.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LmSubscriptionReportingRoutingModule } from './lm-subscription-reportin.routeing';
import { MatCardModule } from '@angular/material/card';



@NgModule({
  declarations: [LmSubscriptionReportingComponent],
  imports: [
    CommonModule,
    NgxChartsModule,
    MatCardModule,
    LmSubscriptionReportingRoutingModule
  ],
  exports: []
})
export class LmSubscriptionReportingModule { }
