<div *ngIf="type === 'EDIT'; else createForm">
    <div class="divTitle">
      <h2>
        <p>
            {{ 'lm-professional.lm-professional-subscription.modal.title' | translate }}
        </p>
      </h2>
    </div>

    <form [formGroup]="subscriptionForm" (ngSubmit)="submit()" *ngIf="subscriptions.length > 0">
      <div class="divBody">
        <mat-radio-group formControlName="subscriptionCode" name="subscriptionCode" *ngIf="!loading">
          <mat-radio-button *ngFor="let item of subscriptions" [value]="item.code" [checked]="item.current" (change)="changeSubscription(item)">
            <span class="wrap-label-text">
              {{ item?.subscriptionDescriptions[0].description }}
            </span>
            <span *ngIf="item.current">{{ 'lm-professional.lm-professional-subscription.modal.subsSelected' | translate }}</span>
          </mat-radio-button><br>
        </mat-radio-group>
        <div *ngIf="!loading">
          <mat-form-field class="textarea">
            <mat-label>{{ 'lm-professional.lm-professional-subscription.modal.comment' | translate }}</mat-label>
            <textarea matInput rows="5" cols="10" formControlName="comment" name="comment"
            placeholder="{{'lm-professional.lm-professional-subscription.modal.comment' | translate}}"></textarea>
          </mat-form-field>
        </div>
      </div>
      <mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
      <div class="divFooter">
        <button mat-raised-button color="primary" type="submit" class="button_submit" [disabled]="!subscriptionForm.valid">
          <mat-icon>save</mat-icon> {{ 'lm-professional.lm-professional-subscription.form.f_button_save' | translate }}
        </button>
        <button mat-raised-button (click)="dismiss()" [disabled]="!loading">
          <mat-icon>save</mat-icon> {{ 'lm-professional.lm-professional-subscription.form.f_button_cancel' | translate }}
        </button>
      </div>
    </form>
</div>

<ng-template #createForm>
  <div>
    <div class="divTitle">
      <h2>
        <p>
            Crear Suscripción
        </p>
      </h2>
    </div>

    <form [formGroup]="subscriptionForm">
      <div class="divBody">
        <mat-radio-group formControlName="subscriptionCode" name="subscriptionCode" *ngIf="!loading">
          <mat-radio-button *ngFor="let item of subscriptions" [value]="item.code" (change)="changeSubscription(item)">
            <span class="wrap-label-text">
              {{ item?.subscriptionDescriptions[0].description }}
            </span>
            <span *ngIf="item.current">{{ 'lm-professional.lm-professional-subscription.modal.subsSelected' | translate }}</span>
          </mat-radio-button><br>
        </mat-radio-group>
        <div *ngIf="!loading">
          <mat-form-field class="textarea">
            <mat-label>{{ 'lm-professional.lm-professional-subscription.modal.comment' | translate }}</mat-label>
            <textarea matInput rows="5" cols="10" formControlName="comment" name="comment"
            placeholder="{{'lm-professional.lm-professional-subscription.modal.comment' | translate}}"></textarea>
          </mat-form-field>
        </div>
      </div>
      <mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
      <div class="divFooter">
        <button mat-raised-button color="primary" type="submit" class="button_submit" [disabled]="!subscriptionForm.valid" (click)="submit()">
          <mat-icon>save</mat-icon> {{ 'lm-professional.lm-professional-subscription.form.f_button_save' | translate }}
        </button>
        <button mat-raised-button (click)="dismiss()">
          <mat-icon>cancel</mat-icon> {{ 'lm-professional.lm-professional-subscription.form.f_button_cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>