export var single = [
  {
    "name": "Mudanzas",
    "value": 60
  },
  {
    "name": "OTM",
    "value": 20
  },
  {
    "name": "Ventiladores",
    "value": 15
  },
    {
    "name": "Cocinas",
    "value": 5
  }
];