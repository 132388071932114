<div class="content">
    <mat-card class="card">
        <mat-card-content>
            <div class="report">
                <h1>Datos Globales</h1>
                    <div class="report__global">
                        <div class="report__circle">
                            <div>
                                N° Solicitudes<br>
                                297
                            </div>
                        </div>
                        <div class="report__circle">
                            <div>
                                N° Solicitudes<br>
                                42
                            </div>
                        </div>
                        <div class="report__circle">
                            <div>
                                Tasa Transformación<br>
                                18.36%
                            </div>
                        </div>
                        <div class="report__circle">
                            <div>
                                Transformación<br>
                                2.989
                            </div>
                        </div>
                        <div class="report__circle">
                            <div>
                                Rating Global<br>
                                4.8
                            </div>
                        </div>
                    </div>
                <div>
                    <ngx-charts-pie-chart
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="single"
                    [gradient]="gradient"
                    [legend]="showLegend"
                    [legendTitle]="'Solicitudes'"
                    [legendPosition]="legendPosition"
                    [labels]="showLabels"
                    [doughnut]="true"
                    [doughnut]="isDoughnut"
                    (select)="onSelect($event)"
                    (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)"
                    >
                    </ngx-charts-pie-chart>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>