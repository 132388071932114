export enum ELmSharedPaths {
	RESETPASSWORD = 'reset-password',
	CREATE_NEW_USER = "new-user",
	BAN_PROFESSIONAL = "ban-professional",
	UNBAN_PROFESSIONAL = 'unban-professional',
	TRANSFERPAYBY = 'transfer-payby',
	TRANSFERSEPA ='transfer-sepa',
	CANCEL_SEPA ='cancel-sepa',
	OFFERED_SERVICES = "offered-services",
	TRANSFERS = "transfers",
	CLIENTS = 'clients',
	PROFESSIONALS = 'professionals',
	SERVICE = 'service',
	BLOCK_PROFESSIONAL = "block-professional",
	REPORTING = "reporting"


}

export interface TransferSepaModel {
	totalCost: string;
	totalPaid: string;
	amount: string;
	operationNumber: number;
	professionalEmail: string;
	operationConcept: string;
	completed?: boolean;
	endTime?: boolean;
}

export interface MasterArea {
	city?: string;
	provinceName?: string;
}